import React, { useState } from "react";
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css";
import emailjs from '@emailjs/browser';
import { Form, InputGroup, Col, Container, Row } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Particle from "../Particle";
import Card from "react-bootstrap/Card";
import {
  ImWhatsapp,
  ImFacebook2,
  ImMail3
} from "react-icons/im";
toast.configure({
  position: "bottom-center",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,

  transition: "Bounce",
});
function ResumeNew() {
  const [nombre, setNombre] = useState("");
  const [contacto, setContacto] = useState("");
  const [consulta, setConsulta] = useState("");
  const [loading, setLoading] = useState(false);
  const handleSubmit = async (e) => {

    e.preventDefault();
    setLoading(true);

    const parametros = {
      nombre: nombre,
      contacto: contacto,
      asunto: "Consulta desde formulario de contacto",
      mensaje: consulta
    };

    try {
      await toast.promise(
        emailjs.send(
          "service_nmqvupw",
          "template_csk7i18",
          parametros,
          "09ZpHR8TjoUREeJk7"
        ),
        {
          pending: "Enviando correo...",
          success: {
            render() {
              setNombre("");
              setContacto("");
              setConsulta("");
              setLoading(false);
              return "Correo enviado con éxito";
            },
          },
          error: {
            render() {
              setLoading(false);
              return "Algo ha salido mal"
            },
          }
        }
      );
    } catch (error) {
      console.error("FAILED...", error);
      setLoading(false);
    }
  };
  return (
    <div>
      <ToastContainer theme="colored" />
      <Container style={{ display: "flex", justifyContent: "center" }} fluid className="resume-section">
        <Particle />



        <Row style={{ maxWidth: "1200px", justifyContent: "center", padding: "10px", position: "relative" }}>
          <Col xs={12} md={4} style={{ marginBottom: "20px" }}>
            <Card className="project-card-view">
              <Card.Body>
                <Card.Text style={{ fontSize: "20px" }}>
                  <ImWhatsapp style={{ color: "#c770f0" }} />&nbsp; +591 77786114
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: "20px" }}>
            <Card className="project-card-view">
              <Card.Body>
                <Card.Text style={{ fontSize: "20px" }}>
                  <ImMail3 style={{ color: "#c770f0" }} />&nbsp; info@ codigoveloz.com
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>
          <Col xs={12} md={4} style={{ marginBottom: "20px" }}>
            <Card className="project-card-view">
              <Card.Body>
                <Card.Text style={{ fontSize: "20px" }}>
                  <ImFacebook2 style={{ color: "#c770f0" }} />&nbsp; Código Veloz
                </Card.Text>
              </Card.Body>
            </Card>
          </Col>



          <div>
            <h1 className="project-heading" style={{ marginBottom: "-20px" }}>
              Envíanos un <strong className="purple">mensaje</strong>
              <span className="wave" role="img" aria-labelledby="wave">
                📧
              </span>
            </h1>
           
           
            <br />
            <br />

          </div>


          <Form onSubmit={handleSubmit} style={{ margin: 0, padding: 0, border: 'none', display: 'contents' }}>

            <Col style={{ padding: "15px", backgroundColor: "#151030" }} xs={12} sm={12} md={10} lg={10}>

              <Form.Group>
                <Form.Label>Nombre</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    id="name"
                    placeholder="Ingrese su nombre"
                    value={nombre}
                    onChange={(e) => setNombre(e.target.value)}
                    required
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, ingrese su nombre.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <br />
            </Col>
            <Col style={{ padding: "15px", backgroundColor: "#151030" }} xs={12} sm={12} md={10} lg={10}>
              <Form.Group >
                <Form.Label>Correo o Teléfono</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    type="text"
                    id="email"
                    placeholder="Ingrese su correo o teléfono"
                    value={contacto}
                    onChange={(e) => setContacto(e.target.value)}
                    required
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, ingrese su correo o teléfono.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <br />
            </Col>




            <Col style={{ padding: "15px", backgroundColor: "#151030" }} xs={12} sm={12} md={10} lg={10}>
              <Form.Group >
                <Form.Label>Consulta</Form.Label>
                <InputGroup hasValidation>
                  <Form.Control
                    as="textarea"
                    rows={3}
                    placeholder="Escriba su consulta aquí"
                    value={consulta}
                    onChange={(e) => setConsulta(e.target.value)}
                    required
                    style={{ minHeight: "150px" }}
                    disabled={loading}
                  />
                  <Form.Control.Feedback type="invalid">
                    Por favor, escriba su consulta.
                  </Form.Control.Feedback>
                </InputGroup>
              </Form.Group>
              <br />
            </Col>


            <Col style={{ marginBottom: "27px", padding: "15px", backgroundColor: "#151030" }} xs={12} sm={12} md={10} lg={10}>
              <Button disabled={loading} variant="primary" type="submit">
                Enviar Consulta
              </Button>

            </Col>

          </Form>
        </Row>
      </Container>
    </div>
  );
}

export default ResumeNew;
