import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import Aboutcard2 from "./AboutCard2";


function About() {
  return (


    <Container style={{ display: "flex", justifyContent: "center" }} fluid className="resume-section">
      <Particle />

      <Row style={{ maxWidth: "1200px", justifyContent: "center", padding: "10px" }}>

      <Col
          md={12}
          style={{
            justifyContent: "center",
            paddingTop: "30px",
            paddingBottom: "50px",
          }}
        >
          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            Desarrollo de sistemas <strong className="purple">a medida</strong>
          </h1>

          <Aboutcard></Aboutcard>
        </Col>

       <Col>
       <Techstack />
      <Aboutcard2></Aboutcard2>
       </Col>

      </Row>
    </Container>


   
  );
}

export default About;
