import React from "react";
import { Col, Row } from "react-bootstrap";

function Techstack() {
  return (
    <>
      <Row style={{ justifyContent: "center", paddingBottom: "50px", margin: "20px" }}>

        <Col style={{ backgroundColor: "#151030" }} xs={12} sm={6} md={4} lg={3} className="tech-icons">

          <img src="/imagenes/web.png" style={{ maxHeight: "250px" }} alt="about" className="img-fluid" />

          <h1 style={{ fontSize: "25px" }} >
            Sistemas <strong className="purple">web </strong>
          </h1>
        </Col>


        <Col style={{ backgroundColor: "#151030" }} xs={12} sm={6} md={4} lg={3} className="tech-icons">
          <img src="/imagenes/pagina.png" style={{ maxHeight: "250px" }} alt="about" className="img-fluid" />

          <h1 style={{ fontSize: "25px" }} >
            Páginas <strong className="purple">web </strong>
          </h1>
        </Col>


        <Col style={{ backgroundColor: "#151030" }} xs={12} sm={6} md={4} lg={3} className="tech-icons">
          <img src="/imagenes/android.png" style={{ maxHeight: "250px" }} alt="about" className="img-fluid" />
          <h1 style={{ fontSize: "25px" }} >
            Aplicaciones <strong className="purple">Android </strong>
          </h1>
        </Col>


        <Col style={{ backgroundColor: "#151030" }} xs={12} sm={6} md={4} lg={3} className="tech-icons">
          <img src="/imagenes/ios.png" style={{ maxHeight: "250px" }} alt="about" className="img-fluid" />
          <h1 style={{ fontSize: "25px" }} >
            Aplicaciones <strong className="purple">iOS </strong>
          </h1>
        </Col>
      </Row>
    </>

  );
}

export default Techstack;
