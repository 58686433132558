import React, { useState } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import logo from "../Assets/logo.png";
import { Link } from "react-router-dom";
import {
  AiOutlineHome,
  AiFillCode,
  AiOutlineCloudServer,
  AiFillProject,
  AiFillMail
} from "react-icons/ai";

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);

  function scrollHandler() {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  }

  window.addEventListener("scroll", scrollHandler);

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="md"
      className={navColour ? "sticky" : "navbar"}
    >


      <Navbar.Brand as={Link} to="/" className="d-flex" style={{ justifyContent: "center", alignItems: "center" }}>
        <img style={{ width: "50px" }} src={logo} className="img-fluid" alt="brand" />

        <span className="d-none d-lg-block" style={{ color: "#2dd4bf" }}>&nbsp; Código <span style={{ color: "#cd5ff8" }}>Veloz</span></span>
      </Navbar.Brand>


      <Navbar.Toggle
        aria-controls="responsive-navbar-nav"
        onClick={() => {
          updateExpanded(expand ? false : "expanded");
        }}
      >
        <span></span>
        <span></span>
        <span></span>
      </Navbar.Toggle>
      <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="ms-auto" defaultActiveKey="#home">
          <Nav.Item style={{ padding: 0, margin: 0 }}>
            <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
              <AiOutlineHome style={{ marginBottom: "2px" }} /> Inicio
            </Nav.Link>
          </Nav.Item>
          <Nav.Item style={{ padding: 0, margin: 0 }}>
            <Nav.Link
              as={Link}
              to="/hosting"
              onClick={() => updateExpanded(false)}
            >
              <AiOutlineCloudServer style={{ marginBottom: "2px" }} /> Hosting
            </Nav.Link>
          </Nav.Item>


          <Nav.Item style={{ padding: 0, margin: 0 }}>
            <Nav.Link
              as={Link}
              to="/desarrollo"
              onClick={() => updateExpanded(false)}
            >
              <AiFillCode style={{ marginBottom: "2px" }} /> Desarrollo
            </Nav.Link>
          </Nav.Item>


          <Nav.Item style={{ padding: 0, margin: 0 }}>
            <Nav.Link
              as={Link}
              to="/proyectos"
              onClick={() => updateExpanded(false)}
            >
              <AiFillProject
                style={{ marginBottom: "2px" }}
              />{" "}
              Proyectos
            </Nav.Link>
          </Nav.Item>

          <Nav.Item style={{ padding: 0, margin: 0 }}>
            <Nav.Link
              as={Link}
              to="/contactanos"
              onClick={() => updateExpanded(false)}
            >
              <AiFillMail style={{ marginBottom: "2px" }} /> Contáctanos
            </Nav.Link>
          </Nav.Item>
        </Nav>
      </Navbar.Collapse>

    </Navbar>
  );
}

export default NavBar;
