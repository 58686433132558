import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import Particle from "../Particle";
import Techstack from "./Techstack";
import Aboutcard from "./AboutCard";
import laptopImg from "../../Assets/about.png";
import Toolstack from "./Toolstack";

function About() {
  return (

    <Container style={{ display: "flex", justifyContent: "center" }} fluid className="resume-section">
      <Particle />

      <Row style={{ maxWidth: "1200px", justifyContent: "center", padding: "10px" }}>

        <Col
          md={7}
          style={{
            justifyContent: "center",
            paddingTop: "30px",
            paddingBottom: "50px",
          }}
        >
          <h1 style={{ fontSize: "2.1em", paddingBottom: "20px" }}>
            Hosting <strong className="purple">VPS</strong>
          </h1>
          <Aboutcard />


        </Col>
        <Col
          md={5}
          style={{ paddingTop: "120px", paddingBottom: "50px" }}
          className="about-img"
        >
          <img src={laptopImg} style={{ maxHeight: "250px" }} alt="about" className="img-fluid" />
        </Col>


        <Col>
          <h1 className="project-heading">
            Tecnologías <strong className="purple">soportadas </strong>
          </h1>
          <Toolstack />
          <Techstack />
        </Col>

      </Row>
    </Container>




  );
}

export default About;
