import React from "react";
import { Col, Row } from "react-bootstrap";
import { ImCloudCheck } from "react-icons/im";
import {

} from "react-icons/si";

function Techstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px", margin: "20px" }}>
      <Col xl={3} lg={4} md={5} xs={12} style={{ margin: "20px", padding: "15px", color: "#fff", backgroundColor: "#151030", border: "solid 3px rgba(200, 137, 230, 0.637)", borderRadius: "6px" }}>
        <h4 className="purple">Básico</h4>
        <h1 style={{ fontSize: "50px" }}>Bs. 200<span style={{ fontSize: "20px" }}> /anual</span> </h1>
        <ul style={{ padding: "0", marginTop: "15px" }}>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 3 GB de almacenamiento
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Tráfico ilimitado
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 1 dominio parqueado
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Certificados SSL
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 5 subdominios
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 20 correos corporativos
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 5 bases de datos
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Wordpress
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Dominios temporales
          </li>
        </ul>
      </Col>
      <Col xl={3} lg={4} md={5} xs={12} style={{ margin: "20px", padding: "15px", color: "#fff", backgroundColor: "#151030", border: "solid 3px rgba(200, 137, 230, 0.637)", borderRadius: "6px" }}>
        <h4 className="purple">Estándar</h4>
        <h1 style={{ fontSize: "50px" }}>Bs. 300<span style={{ fontSize: "20px" }}> /anual</span> </h1>
        <ul style={{ padding: "0", marginTop: "15px" }}>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 6 GB de almacenamiento
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Tráfico ilimitado
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 5 dominio parqueado
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Certificados SSL
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 30 subdominios
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 50 correos corporativos
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 30 bases de datos
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Wordpress
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Dominios temporales
          </li>
        </ul>
      </Col>
      <Col xl={3} lg={4} md={5} xs={12} style={{ margin: "20px", padding: "15px", color: "#fff", backgroundColor: "#151030", border: "solid 3px rgba(200, 137, 230, 0.637)", borderRadius: "6px" }}>
        <h4 className="purple">Premium</h4>
        <h1 style={{ fontSize: "50px" }}>Bs. 400<span style={{ fontSize: "20px" }}> /anual</span> </h1>
        <ul style={{ padding: "0", marginTop: "15px" }}>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> 10 GB de almacenamiento
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Tráfico ilimitado
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Dominio parqueados ilimitados
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Certificados SSL
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Subdominios ilimitados
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Correos corporativos ilimitados
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Bases de datos ilimitadas
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Wordpress
          </li>
          <li className="about-activity" style={{ marginBottom: "10px" }}>
            <ImCloudCheck style={{ color: "#c770f0" }} /> Dominios temporales
          </li>
        </ul>
      </Col>



    </Row>
  );
}

export default Techstack;
