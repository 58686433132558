import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import myImg from "../../Assets/avatar.svg";
import Tilt from "react-parallax-tilt";
import {
  AiFillFacebook,
  AiFillMail,
  AiOutlineWhatsApp
} from "react-icons/ai";

function Home2() {
  return (
    <Container fluid className="home-about-section" style={{ marginTop: "-100px" }} id="about">
      <Container>
        <h1 style={{ fontSize: "2.6em", color: "#fff", marginBottom: "-50px" }}>
          SOLUCIONES <span className="purple"> WEB </span> INNOVADORAS
        </h1>
        <Row>
          <Col md={8} className="home-about-description">

            <h3 className="purple" style={{ marginBottom: "-40px", textAlign: "left" }}>Apoyamos tu emprendimiento o negocio</h3>

            <p className="home-about-body">
              Desarrollamos sitios web y sistemas en línea que impulsan el éxito de tu negocio, mejorando la eficiencia operativa y facilitando la interacción con tus clientes y socios.
            </p>
            <br />
            <h3 className="purple" style={{ marginBottom: "-40px", textAlign: "left" }}>Diseño personalizado y adaptable</h3>

            <p className="home-about-body">
              Ofrecemos diseños únicos y responsive que se ajustan a las necesidades específicas de cada cliente, garantizando una experiencia de usuario excepcional.
            </p>
            <br />
            <h3 className="purple" style={{ marginBottom: "-40px", textAlign: "left" }}>Seguridad y protección de datos</h3>

            <p className="home-about-body">
              Implementamos medidas de seguridad avanzadas y cumplimos con los estándares de protección de datos para garantizar la privacidad y seguridad de la información de tu negocio y tus clientes.
            </p>
            <br />
            <h3 className="purple" style={{ marginBottom: "-40px", textAlign: "left" }}>Soporte y mantenimiento continuo</h3>

            <p className="home-about-body">
              Brindamos un servicio de soporte técnico proactivo y eficiente, asegurando el correcto funcionamiento de tu sitio web y la resolución rápida de cualquier inconveniente.
            </p>
          </Col>
          <Col md={4} className="myAvtar d-flex align-items-center justify-content-center">
            <Tilt>
              <img src={myImg} className="img-fluid" alt="avatar" />
            </Tilt>
          </Col>
        </Row>
        <Row>
          <Col md={12} className="home-about-social">
            <h1>CONTÁCTANOS EN</h1>

            <ul className="home-about-social-links">
              <li className="social-icons">
                <a
                  href="https://www.facebook.com/people/C%C3%B3digo-Veloz/100091562630339/"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiFillFacebook />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="mailto:info@codigoveloz.com"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour home-social-icons"
                >
                  <AiFillMail />
                </a>
              </li>
              <li className="social-icons">
                <a
                  href="https://api.whatsapp.com/send/?phone=+59177786114"
                  target="_blank"
                  rel="noreferrer"
                  className="icon-colour  home-social-icons"
                >
                  <AiOutlineWhatsApp />
                </a>
              </li>
            </ul>
          </Col>
        </Row>
      </Container>
    </Container>
  );
}
export default Home2;
