import React from "react";
import Card from "react-bootstrap/Card";
function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            Nos apasiona el desarrollo de sistemas web y estamos comprometidos en ofrecer soluciones innovadoras y eficaces que ayuden a nuestros clientes a mejorar sus procesos empresariales y alcanzar sus objetivos en línea. Creemos en la importancia de la tecnología para mejorar la vida de las personas y nos esforzamos por estar a la vanguardia de las últimas tendencias y tecnologías.
          </p>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
