import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import ProjectCard from "./ProjectCards";
import Particle from "../Particle";

function Projects() {
  return (
    <Container fluid className="project-section">
      <Particle />
      <Container>
        <h1 className="project-heading">
          Proyectos <strong className="purple">recientes </strong>
        </h1>

        <Row style={{ justifyContent: "center", paddingBottom: "10px" }}>
          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/lallamita.png"
              isBlog={false}
              title="LA LLAMITA S.R.L."
              description="Gestor de tiendas online disponible en plataformas web, Android e iOS, con funcionalidades como chat, pasarela de pagos, gestión de productos, gestión de clientes, cupones y mucho más."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/ot.png"
              isBlog={false}
              title="DAIFO TELECOM S.R.L."
              description="Sistema de gestión de clientes y de trabajos, con funcionalidades como digitalización de firmas, carga y optimización de imágenes, estadísticas anuales y mensuales."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/soya.png"
              isBlog={false}
              title="DISTRIBUIDORA"
              description="Sistema avanzado de administración de una empresa, con módulos de ventas, roles, clientes, tesorería, almacén y más."
            />
          </Col>


          {/*  
          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/auto.png"
              isBlog={false}
              title="REPUESTOS AUTOBO"
              description="Sistema de cotización y administración de pedidos, con la función de rastrear el estado de algún pedido, generación de PDF, envío de correo y mensajería instantánea (SMS)."
            />
          </Col>
          */}

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/daifo.png"
              isBlog={false}
              title="DAIFO TELECOM S.R.L."
              description="Página web a medida para una empresa de telecomunicaciones líder en su campo."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/iifcom.png"
              isBlog={false}
              title="IIFCOM"
              description="Página web a medida para una empresa especializada en la instalación y mantenimiento de redes."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/trigomente.png"
              isBlog={false}
              title="TRIGOMENTE"
              description="Sistema web y aplicación Android para apoyar el aprendizaje a estudiantes de secundaria, con métodos creativos y didácticos como desafíos, ranking y participación colectiva."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/jeandimi.png"
              isBlog={false}
              title="JEANDIMI"
              description="Página web con un sistema adicional para gestionar los productos de la librería, como material escolar, artículos de bazar, telas y productos propios de Jeandimi."
            />
          </Col>

          <Col md={6} sm={12} lg={4} className="project-card">
            <ProjectCard
              imgPath="/imagenes/sumawasi.png"
              isBlog={false}
              title="SUMAWASI"
              description="Sistema inmobiliario para la compra venta y anticrético de terrenos, casas y departamentos, con un sistema sofisticado de notificaciones y con validades de seguridad para controlar las posibles estafas en temas de inmuebles."
            />
          </Col>
        </Row>



      </Container>
    </Container>
  );
}

export default Projects;
