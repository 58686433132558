import React from "react";
import { Col, Row } from "react-bootstrap";
import {
  SiPhp,
  SiRuby,
  SiDotnet,
  SiLaravel,
  SiNodedotjs,
  SiGit,
  SiDocker,
  SiApache,
  SiNginx,
  SiMysql,
  SiPostgresql,
  SiWordpress
} from "react-icons/si";

function Toolstack() {
  return (
    <Row style={{ justifyContent: "center", paddingBottom: "50px" }}>
      <Col xs={4} md={2} className="tech-icons">
        <SiPostgresql />
        <h6 style={{ fontSize: "20px" }}>Postgresql</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiMysql />
        <h6 style={{ fontSize: "20px" }}>Mysql</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiPhp />
        <h6 style={{ fontSize: "20px" }}>Php</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNodedotjs />
        <h6 style={{ fontSize: "20px" }}>Node.js</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiRuby />
        <h6 style={{ fontSize: "20px" }}>Ruby</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDotnet />
        <h6 style={{ fontSize: "20px" }}>.NET</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiLaravel />
        <h6 style={{ fontSize: "20px" }}>Lavavel</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiGit />
        <h6 style={{ fontSize: "20px" }}>Git</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiDocker />
        <h6 style={{ fontSize: "20px" }}>Docker</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiApache />
        <h6 style={{ fontSize: "20px" }}>Apache</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiNginx />
        <h6 style={{ fontSize: "20px" }}>Nginx</h6>
      </Col>
      <Col xs={4} md={2} className="tech-icons">
        <SiWordpress />
        <h6 style={{ fontSize: "20px" }}>Wordpress</h6>
      </Col>
    </Row>
  );
}

export default Toolstack;
