import React, { useState, useEffect } from "react";
import Preloader from "../src/components/Pre";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import Desarrollo from "./components/Desarrollo/About";
import Proyectos from "./components/Proyectos/Projects";
import Footer from "./components/Footer";
import Hosting from "./components/Hosting/About";

import Contactanos from "./components/Contactanos/Projects";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";

import { FloatingWhatsApp } from 'react-floating-whatsapp'
function App() {
  const [load, upadateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      upadateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <Preloader load={load} />
      <div className="App" id={load ? "no-scroll" : "scroll"}>
        <Navbar />
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/proyectos" element={<Proyectos />} />
          <Route path="/desarrollo" element={<Desarrollo />} />
          <Route path="/hosting" element={<Hosting />} />
          <Route path="/contactanos" element={<Contactanos />} />
          <Route path="*" element={<Navigate to="/" />} />
        </Routes>
        <Footer />
        <FloatingWhatsApp
          phoneNumber="+59177786114"
          accountName="Código Veloz"
          darkMode={false}
          notification={false}
          chatMessage="¡Hola! ¿En qué podemos ayudarte?"
          placeholder="Escribe tu mensaje aquí..."
          messageDelay={1}
          avatar="/favicon.ico"
          statusMessage="+591 77786114"
          allowClickAway={true}
          allowEsc={true}
          style={{ bottom: 80, right: 20 }}
          chatboxHeight={290}

        />
      </div>
    </Router>
  );
}

export default App;
