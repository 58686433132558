import React from "react";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }} className="purple">
            Descubre nuestras soluciones digitales para potenciar tu negocio. Desde la gestión de ventas hasta aplicaciones móviles y plataformas sociales, tenemos todo lo que necesitas para impulsar tu éxito.
          </p>
          <ul>

            <li className="about-activity">
              <ImCheckmark /> Digitalización de documentos
            </li>
            <li className="about-activity">
              <ImCheckmark /> Chat en tiempo real
            </li>
            <li className="about-activity">
              <ImCheckmark /> Gestión de ventas e inventario
            </li>
            <li className="about-activity">
              <ImCheckmark /> Tienda en línea
            </li>
            <li className="about-activity">
              <ImCheckmark /> Sistema de reservas y citas
            </li>
            <li className="about-activity">
              <ImCheckmark /> Sistema de gestión de clientes <strong className="purple">(CRM)</strong>
            </li>
            <li className="about-activity">
              <ImCheckmark /> Plataforma de aprendizaje en línea <strong className="purple">(LMS)</strong>
            </li>
            <li className="about-activity">
              <ImCheckmark /> Sistema de gestión de proyectos
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de entrega de alimentos
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de reservas de alojamiento
            </li>
            <li className="about-activity">
              <ImCheckmark /> Plataforma de redes sociales
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de seguimiento de salud y fitness
            </li>
            <li className="about-activity">
              <ImCheckmark /> Portal de noticias
            </li>
            <li className="about-activity">
              <ImCheckmark /> Sistema de gestión de eventos
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de mensajería instantánea
            </li>
            <li className="about-activity">
              <ImCheckmark /> Plataforma de crowdfunding
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de transporte compartido
            </li>
            <li className="about-activity">
              <ImCheckmark /> Sistema de reservas de viajes
            </li>
            <li className="about-activity">
              <ImCheckmark /> Directorio de empresas
            </li>
            <li className="about-activity">
              <ImCheckmark /> Portal de empleo
            </li>
            <li className="about-activity">
              <ImCheckmark /> Aplicación de juegos móviles
            </li>

          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
