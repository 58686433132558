import React from "react";
import Card from "react-bootstrap/Card";
import { ImCheckmark } from "react-icons/im";

function AboutCard() {
  return (
    <Card className="quote-card-view">
      <Card.Body>
        <blockquote className="blockquote mb-0">
          <p style={{ textAlign: "justify" }}>
            ¡Obtén el mejor precio en nuestros servicios de Hosting eligiendo el plan que mejor se adapte a tus necesidades!
          </p>
          <ul>

            <li className="about-activity">
              <ImCheckmark /> Panel de control <strong className="purple">PLESK</strong>
            </li>
            <li className="about-activity">
              <ImCheckmark /> Accesibilidad <strong className="purple">99.99%</strong>
            </li>
            <li className="about-activity">
              <ImCheckmark /> <strong className="purple">DNS</strong> personalizados
            </li>
          </ul>
        </blockquote>
      </Card.Body>
    </Card>
  );
}

export default AboutCard;
